/* eslint-disable react/no-unused-state */
import { DownOutlined } from '@ant-design/icons';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  css,
  JsonResponse,
  SupersetClient,
  supersetTheme,
  t,
  withTheme,
} from '@superset-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { AutoComplete, List, AsyncSelect } from 'src/components';
import { loadTags } from 'src/components/Tags/utils';
import Collapse from 'src/components/Collapse';
import Icons from 'src/components/Icons';
import CustomListItem from 'src/explore/components/controls/CustomListItem';
import adhocFilterType from 'src/explore/components/controls/FilterControl/adhocFilterType';
import SelectControl from 'src/explore/components/controls/SelectControl';
import ControlHeader from 'src/explore/components/ControlHeader';
import AdvancedMetricFilterControl, {
  convertFilterGroupToAdhocFilter,
  convertSqlToAdvancedFilterGroup,
} from 'src/explore/components/controls/AdvancedMetricFilterControl/AdvancedMetricFilterControl';
import {
  HeaderContainer,
  AddIconButton,
} from 'src/explore/components/controls/OptionControls';
import type { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';
import { ceil, isArray, random } from 'lodash';
import { SQLEditor } from 'src/components/AsyncAceEditor';
import {
  CalValueTypes,
  MultiMetricProps,
  MultiMetricState,
  MultiMetricValue,
  MultiMetricValueTypes,
} from './multiMetricTypes';
import AdhocMetric, {
  EXPRESSION_TYPES,
} from './explore/components/controls/MetricControl/AdhocMetric';
import { Tooltip } from './components/Tooltip';
import { StyledColumnOption } from './explore/components/optionRenderers';
import TextControl from './explore/components/controls/TextControl';
import Alert from './components/Alert';
import { validateCalculation, getCalMeasureArray, getCalValueType, getMeasureIds, getMeasureByName } from './multiMetricUtilities';

const SortableListItem = SortableElement(CustomListItem);
const SortableList = SortableContainer(List);
const SortableDragger = SortableHandle(() => (
  <Tooltip title="Move item" placement="right">
    <i
      role="img"
      aria-label="drag"
      className="fa fa-bars text-primary"
      style={{ cursor: 'move' }}
    />
  </Tooltip>
));

const iconStyles = css`
&.anticon {
  font-size: unset;
  .anticon {
    line-height: unset;
    vertical-align: unset;
  }
}
`;

const SectionValidateTooltip = () => {
  const theme = supersetTheme;
  return (
    <Tooltip
      id="validation-errors-tooltip"
      title={`Add calculated measures to calculate measures and numbers. 
    Please enclose measures in double quotes.
    Operators only support +, -, *, /, >, <, >=, <=, ( and ).
    E.g., ("Measure 1" + "Measure 2") * 3`}
    >
      <Icons.InfoCircleOutlined
        css={css`
        ${iconStyles}
        color: ${theme.colors.grayscale.base};
      `}
      />
    </Tooltip>
  );
};

const timeSeriesCharts = [
  'mixed_timeseries',
  'echarts_area',
  'echarts_timeseries_bar',
  'echarts_timeseries_line',
  'echarts_timeseries_scatter',
  'echarts_timeseries_smooth',
  'echarts_timeseries_step',
  'rose',
  'partition',
  'big_number',
  'area',
  'bar',
  'line',
  'measure_line',
];

const CustomCollapse = (props: any) => {
  const { children, extra, isExpand } = props;
  const [activeKey, setActiveKey] = React.useState<string[]>([]);
  const customCollapseRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    setActiveKey(isExpand === true ? ['0'] : []);
  }, [isExpand]);

  const customHeader = (
    <DownOutlined
      onClick={() => {
        setActiveKey(activeKey.length === 0 ? ['0'] : []);
      }}
      style={{ paddingTop: '8px', paddingRight: '8px' }}
      rotate={activeKey.length === 0 ? 0 : 180}
    />
  );
  return (
    <Collapse
      ghost
      bordered
      collapsible={'icon' as CollapsibleType}
      css={css`
      .ant-collapse-item {
        .ant-collapse-header {
          border-bottom: 0px;
          padding: 0px !important;
          display: inline-block;
          width: 100%;
        }
      }
      .ant-collapse-extra {
        width: calc(100% - 24px);
      }
      width: 100%;
    `}
      activeKey={activeKey}
    >
      <div ref={customCollapseRef} />
      <Collapse.Panel
        showArrow={false} // hide the default arrow so that we can use the custom one
        key="0"
        header={customHeader}
        extra={extra}
        css={css`
        .ant-collapse-header {
          padding: 0px;
        }
      `}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

const loadSavedMeasure = async (datasetId: number) => {
  const res = await SupersetClient.get({
    endpoint: `/api/v1/measure/${datasetId}/related_objects/`,
  }).then((response: JsonResponse) => response);
  const measures = res.json.measures.map((measure: any) => ({
    ...JSON.parse(measure.measurements),
    rolling_type: measure.rolling_type,
    rolling_periods: measure.rolling_periods,
    measure_id: measure.measure_id,
  }));
  return measures;
};

export class MultiMetricControl extends React.Component<
  MultiMetricProps,
  MultiMetricState
> {
  static defaultProps = {
    value: [
      {
        key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
        MeasureName: 'Measure 1',
        isExpand: true,
        isValid: true,
        SelectControlColumn: 'events',
        SelectControlAggregator: 'COUNT',
        hasChanged: true,
        metric: {
          expressionType: 'SQL',
          sqlExpression: 'COUNT(1)',
          column: {
            column_name: '1',
          },
          aggregate: 'COUNT',
          isNew: false,
          hasCustomLabel: true,
          label: 'Measure 1',
          optionName: 'metric_zfdbv4iioe8_qfvjd0ujka',
        },
        tag: '',
        tags: [],
      },
    ],
    savedMetrics: [],
    selectedMetrics: [],
    filterOptions: [false, false, false, false, false, false, false],
  };

  customCollapseRef: React.RefObject<HTMLDivElement>;

  aceEditorRef: any;

  constructor(props: MultiMetricProps) {
    super(props);
    this.state = {
      savedMeasures: [],
      selectControlAggregatorConfig: {
        name: 'selectControlAggregator',
        multi: false,
        choices: [
          'AVG',
          'COUNT',
          'MAX',
          'MIN',
          'SUM',
          'COUNT_DISTINCT',
          'PERCENTILE',
        ],
      },
    };
    this.customCollapseRef = React.createRef();
    this.handleAceEditorRef = this.handleAceEditorRef.bind(this);
  }

  componentDidUpdate(preProps: MultiMetricProps) {
    if (preProps?.value?.length !== this.props.value.length)
      this.customCollapseRef.current?.parentElement?.children[
        this.props.value.length
      ]
        ?.querySelector('input')
        ?.focus();
    if (this.aceEditorRef) {
      this.aceEditorRef?.editor?.resize();
    }
  }

  private renderColumnOption(option: any) {
    const column = { ...option };
    if (column.metric_name && !column.verbose_name) {
      column.verbose_name = column.metric_name;
    }
    return <StyledColumnOption column={column} showType />;
  }

  componentDidMount() {
    loadSavedMeasure(this.props.datasource.id).then(res => {
      this.setState({ savedMeasures: res });
    });
    if (this.props.filterOptions) {
      this.props.filterOptions.fill(false);
    }
    if (this.props.value) {
      if (this.props.multi === false) {
        if (
          this.props.form_data.metric &&
          this.props.form_data.metric === 'uniqUser'
        ) {
          const metricValue = {
            key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
            MeasureName: 'uniqUser',
            isExpand: true,
            isValid: true,
            SelectControlColumn: 'user_id',
            SelectControlAggregator: 'UNIQUE',
            metric: {
              expressionType: 'SQL',
              sqlExpression: 'uniq(user_id)',
              column: {
                column_name: 'user_id',
              },
              aggregate: 'UNIQUE',
              isNew: false,
              hasCustomLabel: true,
              label: 'uniqUser',
            },
          };
          this.props.value[0] = metricValue;
          this.props.form_data.metrics = [];
          this.props.form_data.metrics[0] = metricValue.metric;
        } else if (
          this.props.form_data.metric &&
          this.props.form_data.metric === 'uniqDevice'
        ) {
          const metricValue = {
            key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
            MeasureName: 'uniqDevice',
            isExpand: true,
            isValid: true,
            SelectControlColumn: 'device_id',
            SelectControlAggregator: 'UNIQUE',
            metric: {
              expressionType: 'SQL',
              sqlExpression: 'uniq(device_id)',
              column: {
                column_name: 'device_id',
              },
              aggregate: 'UNIQUE',
              isNew: false,
              hasCustomLabel: true,
              label: 'uniqDevice',
            },
          };
          this.props.value[0] = metricValue;
          this.props.form_data.metrics = [];
          this.props.form_data.metrics[0] = metricValue.metric;
        } else if (
          this.props.form_data.metric &&
          this.props.form_data.metric !== 'count' &&
          this.props.form_data.metric.expressionType !== 'SQL'
        ) {
          const { metric } = this.props.form_data;
          const metricValue = {
            key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
            MeasureName: metric.label,
            isExpand: true,
            isValid: true,
            SelectControlColumn: metric.column.column_name,
            SelectControlAggregator: metric.aggregate,
            metric,
          };
          this.props.value[0] = metricValue;
          this.props.form_data.metrics = [];
          this.props.form_data.metrics[0] = metric;
        } else if (
          this.props.form_data.metric &&
          this.props.form_data.metric.expressionType === 'SQL'
        ) {
          const { metric } = this.props.form_data;
          const newMetric = new AdhocMetric({
            ...metric,
            expressionType: 'SIMPLE',
          });
          let metricValue = {};
          // For original single metric, convert it to new measurement with adhoc filter.
          if (
            metric.filterSql &&
            newMetric.translateAdvancedMetricsToSql(metric.filterSql) ===
            metric.sqlExpression
          ) {
            metricValue = {
              key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
              MeasureName: metric.label,
              isExpand: true,
              isValid: true,
              SelectControlColumn: metric.column.column_name,
              SelectControlAggregator: metric.aggregate,
              AdhocFilterControl: convertFilterGroupToAdhocFilter(
                convertSqlToAdvancedFilterGroup(metric.filterSql),
              ),
              metric,
            };
            this.props.value[0] = metricValue;
            this.props.form_data.metrics = [];
            this.props.form_data.metrics[0] = metric;
          } else {
            metricValue = {
              key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
              MeasureName: 'Measure 1',
              isExpand: true,
              isValid: true,
              SelectControlColumn: 'events',
              SelectControlAggregator: 'COUNT',
              metric,
            };
            this.props.value[0] = metricValue;
            this.props.form_data.metrics = [];
            this.props.form_data.metrics[0] = metric;
          }
        }
        if (this.props.controls.metrics.value) {
          this.props.controls.metrics.value[0] = this.props.value[0].metric;
        } else {
          this.props.controls.metrics.value = [];
          this.props.controls.metrics.value[0] = this.props.value[0].metric;
        }
        if (this.props.form_data.metrics) {
          this.props.form_data.metrics[0] = this.props.value[0].metric;
        } else {
          this.props.form_data.metrics = [];
          this.props.form_data.metrics[0] = this.props.value[0].metric;
        }
      } else if (isArray(this.props.value)) {
        if (this.props.controls.metrics.value) {
          for (let i = 0; i < this.props.value.length; i += 1) {
            this.props.controls.metrics.value[i] = this.props.value[i].metric;
          }
        } else {
          this.props.controls.metrics.value = [];
          for (let i = 0; i < this.props.value.length; i += 1) {
            this.props.controls.metrics.value.push(this.props.value[i].metric);
          }
        }
        if (this.props.form_data.metrics) {
          for (let i = 0; i < this.props.value.length; i += 1) {
            this.props.form_data.metrics[i] = this.props.value[i].metric;
          }
        } else {
          this.props.form_data.metrics = [];
          for (let i = 0; i < this.props.value.length; i += 1) {
            this.props.form_data.metrics.push(this.props.value[i].metric);
          }
        }
      }
    }
    const viz_type = this.props.form_data?.viz_type;
    if (!timeSeriesCharts.includes(viz_type)) {
      loadSavedMeasure(this.props.datasource.id).then(res => {
        const measureWithoutRollingWindows = res.filter(
          (measure: any) =>
            !(measure.rolling_type && measure.rolling_type !== 'None'),
        );
        this.setState({ savedMeasures: measureWithoutRollingWindows });
      });
    } else {
      loadSavedMeasure(this.props.datasource.id).then(res => {
        this.setState({ savedMeasures: res });
      });
    }
    this.props.onChange(this.props.value);
  }

  private checkName = (index: number, name: string) => {
    const { savedMeasures } = this.state;
    const measureList = this.props.value.filter((_, i) => i !== index);
    const measureNames = measureList.map(measure => measure.MeasureName);
    const savedMeasureNames = savedMeasures.map(
      (measure: { MeasureName: any }) => measure.MeasureName,
    );
    const allMeasureNames = [...measureNames, ...savedMeasureNames];
    const isNameExist = allMeasureNames.includes(name);
    return isNameExist;
  };

  private onChange: (
    i: number,
    type: MultiMetricValueTypes,
    value: string | PropTypes.InferProps<typeof adhocFilterType> | any[] | any,
  ) => void = (
    i: number,
    type: MultiMetricValueTypes,
    value: string | PropTypes.InferProps<typeof adhocFilterType> | any[] | any,
  ) => {
      const newValue = [...this.props.value];
      if (type === MultiMetricValueTypes.AddMeasure) {
        newValue[i] = value;
      } else if (type === MultiMetricValueTypes.SavedMeasure) {
        newValue[i] = { ...this.props.value[i], MeasureName: value };
        newValue[i] = this.selectSavedMeasure(i, newValue[i]);
      } else {
        newValue[i] = { ...this.props.value[i], [type]: value };
      }
      if (type === MultiMetricValueTypes.measure_id) {
        newValue[i].hasChanged = false;
      }
      if (
        newValue[i] !== this.props.value[i] &&
        type !== MultiMetricValueTypes.measure_id
      )
        newValue[i] = { ...newValue[i], hasChanged: true };
      if (type === MultiMetricValueTypes.MeasureName) {
        this.props.filterOptions[i] = true;
        if (this.checkName(i, value)) {
          newValue[i] = { ...newValue[i], ValidName: false };
        } else newValue[i] = { ...newValue[i], ValidName: true };
      }
      if (type === MultiMetricValueTypes.SelectControlAggregator) {
        if (value !== 'PERCENTILE') {
          newValue[i].SelectControlPercentile = undefined;
        } else {
          newValue[i].SelectControlColumn = undefined;
        }
      }

    if (
      newValue[i].MeasureName &&
      newValue[i].MeasureName !== '' &&
      newValue[i].SelectControlAggregator &&
      newValue[i].SelectControlColumn
    ) {
      newValue[i] = { ...newValue[i], isValid: true };
      const col = this.props.columns.find(
        col => col.column_name === newValue[i].SelectControlColumn,
      );
      let adhocMetric = new AdhocMetric({
        column: col,
        percentile: newValue[i].SelectControlPercentile,
        aggregate: newValue[i].SelectControlAggregator,
        label: newValue[i].MeasureName,
        expressionType: EXPRESSION_TYPES.SIMPLE,
        hasCustomLabel: true,
        datasource: {
          database: {
            backend: this.props.datasource?.database?.backend,
          },
        },
      });
      if (newValue[i].SelectControlColumn === 'events') {
        adhocMetric = adhocMetric.duplicateWith({
          column: { column_name: '1' },
        });
        adhocMetric = adhocMetric.duplicateWith({
          sqlExpression: adhocMetric.translateAdvancedMetricsToSql(),
          expressionType: EXPRESSION_TYPES.SQL,
        });
      }
      if (
        newValue[i].SelectControlColumn !== 'events' &&
        newValue[i].SelectControlAggregator === 'COUNT' &&
        this.props.datasource.database.backend.includes('clickhouse')
      ) {
        adhocMetric = adhocMetric.duplicateWith({
          aggregate: 'UNIQUE',
        });
        adhocMetric = adhocMetric.duplicateWith({
          sqlExpression: adhocMetric.translateAdvancedMetricsToSql(),
          expressionType: EXPRESSION_TYPES.SQL,
        });
      }
      if (newValue[i].SelectControlAggregator === 'COUNT_DISTINCT'){
        adhocMetric = adhocMetric.duplicateWith({
          aggregate: 'COUNT_DISTINCT',
        });
        adhocMetric = adhocMetric.duplicateWith({
          sqlExpression: adhocMetric.translateAdvancedMetricsToSql(),
          expressionType: EXPRESSION_TYPES.SQL,
        });
      }
      if (
        newValue[i].SelectControlAggregator === 'PERCENTILE' &&
        newValue[i].SelectControlPercentile
      ) {
        adhocMetric = adhocMetric.duplicateWith({
          aggregate: 'PERCENTILE',
        });
        adhocMetric = adhocMetric.duplicateWith({
          sqlExpression: adhocMetric.translateAdvancedMetricsToSql(),
          expressionType: EXPRESSION_TYPES.SQL,
        });
      }
      if (
        newValue[i].AdhocFilterControl &&
        newValue[i].AdhocFilterControl.length > 0
      ) {
        // WHEN DEBUG COULD CHECKME HERE
        const sqlExpression = adhocMetric.translateAdvancedMetricsToSql(
          newValue[i].AdhocFilterControl[0].sqlExpression,
        );
        adhocMetric = adhocMetric.duplicateWith({
          filterSql: newValue[i].AdhocFilterControl[0].sqlExpression,
          sqlExpression,
          expressionType: EXPRESSION_TYPES.SQL,
        });
      }
      if (adhocMetric.isValid()) {
        if (this.props.controls.metrics.value) {
          this.props.controls.metrics.value[i] = adhocMetric;
        } else {
          this.props.controls.metrics.value = [];
          this.props.controls.metrics.value.push(adhocMetric);
        }
        if (
          this.props.form_data.metrics &&
          this.props.form_data.metrics === 'count'
        ) {
          this.props.form_data.metrics[i] = adhocMetric;
        } else {
          this.props.form_data.metrics = [];
          this.props.form_data.metrics.push(adhocMetric);
        }
        newValue[i] = { ...newValue[i], metric: adhocMetric };
      }
    } else {
      newValue[i] = { ...newValue[i], isValid: false };
    }
    // If the measure in chart is updated, then update the calculated measure
    if (type === MultiMetricValueTypes.MeasureName) {
      const key = newValue[i].key?.toString() ?? '';
      for (let j = 0; j < newValue.length; j += 1) {
        if (j === i) continue;
        if (newValue[j].isCalculatedMeasure) {
          const calculatedMeasures = newValue[j].calculatedMeasures ?? [];
          for (let k = 0; k < calculatedMeasures?.length; k += 1) {
            const measureValue = newValue[j]?.calculatedMeasures[k]?.value;
            const { type } = newValue[j].calculatedMeasures[k];
            const valueType = type || getCalValueType(measureValue, this.state.savedMeasures, this.getAllMeasures());
            // If the value is the chart measure name, check the measure key
            if (this.isChartMeasure(valueType)) {
              // Update the measure name and sql expression
              if (calculatedMeasures[k].key === key) {
                newValue[j].calculatedMeasures[k].value = value;
                newValue[j].calculatedMeasures[k].measureName = value;
                const sql = this.getCalculatedMeasureSql(newValue[j].calculatedMeasures);
                const calculatedMeasureLogic = this.getCalculatedMeasureLogic(newValue[j].calculatedMeasures)
                if (newValue[j].metric) {
                  newValue[j].metric.sqlExpression = sql;
                  newValue[j].calculatedMeasureLogic = calculatedMeasureLogic;
                  this.props.controls.metrics.value[j].sqlExpression = sql;
                }
                newValue[j].isValidExpression = value !== undefined && value !== '';
              }
            }
          }
        }
      }
    }

      this.props.onChange(newValue);
    };

  // Given a calculated measure value array, get the calculated measure logic
  private getCalculatedMeasureLogic = (calculatedMeasures: any) => {
    let calculatedMeasureLogic = '';
    for (let i = 0; i < calculatedMeasures.length; i += 1) {
      const { value, type } = calculatedMeasures[i];
      const valueType = type || getCalValueType(value, this.state.savedMeasures, this.getAllMeasures());
      if (valueType === CalValueTypes.SavedMeasure || valueType === CalValueTypes.ChartMeasure) {
        calculatedMeasureLogic += `"${value}" `;
      }
      else {
        calculatedMeasureLogic += `${value} `;
      }
    }
    return calculatedMeasureLogic;
  }

  private selectSavedMeasure: (
    index: number,
    measure: any,
  ) => MultiMetricValue = (index: number, measure: any) => {
    const { savedMeasures } = this.state;
    if (isArray(savedMeasures)) {
      let savedMeasure = savedMeasures.find(
        (savedMeasure: { MeasureName: any }) =>
          savedMeasure.MeasureName === measure.MeasureName,
      );
      if (savedMeasure) {
        savedMeasure = {
          ...savedMeasure,
          isExpand: false,
          key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
        };
        this.props.filterOptions[index] = false;
        return savedMeasure;
      }
      return measure;
    }
    return measure;
  };

  private onAdd: () => void = () => {
    const newMeasure = {
      key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
      MeasureName: `Measure ${this.props.value.length + 1}`,
      isExpand: true,
      SelectControlColumn: 'events',
      SelectControlAggregator: 'COUNT',
    };
    this.onChange(
      this.props.value.length,
      MultiMetricValueTypes.AddMeasure,
      newMeasure,
    );
  };

  // Add a new calculated measure
  private onAddCalculation: () => void = () => {
    const newMeasure = {
      // Each measure should have a unique key, and measure name
      key: Date.parse(new Date().toString()) + ceil(random(0, 1000)),
      MeasureName: `Measure ${this.props.value.length + 1}`,
      isExpand: true,
      // Check if the calculated measure is valid
      isValidExpression: false,
      sqlExpression: '',
      // measure_ids is the array of saved measures used in calculated measure
      measure_ids: [],
      // calculated measure array
      // It contains the detial info about each value in the expression
      calculatedMeasures: [],
      // isCalculatedMeasure represents if the current measure is a calculated measure
      isCalculatedMeasure: true,
      // The version 2 supports uses to input the calculated measure logic
      // instead of select one by one
      calMeasureVersion: 2,
      calculatedMeasureLogic: '',
    };
    this.onChange(
      this.props.value.length,
      MultiMetricValueTypes.AddMeasure,
      newMeasure,
    );
  };

  // Get detail info about each value in expression
  private getCalMeasures = (valueArray: any, savedMeasures: any, allMeasure: any) => {
    const calculatedMeasures = [];
    for (let i = 0; i < valueArray.length; i += 1) {
      const value = valueArray[i];
      const valueType = getCalValueType(value, savedMeasures, allMeasure);
      // If the value is a saved measure or measure in chart, then get the measure details
      if ([CalValueTypes.SavedMeasure, CalValueTypes.ChartMeasure].includes(valueType)) {
        const measure = getMeasureByName(value, allMeasure);
        if (measure) {
          const { metric } = measure;
          calculatedMeasures.push({
            value,
            sqlExpression: this.getCalMeasureSql(measure),
            key: measure.key?.toString(),
            measure_id: measure?.measure_id?.toString(),
            measureName: metric.label,
            type: valueType,
            aggregator: metric.aggregate,
            column: metric.column,
            rolling_type: measure.rolling_type,
            rolling_periods: measure.rolling_periods,
            filterSql: metric.filterSql || undefined,
          });
        }
      } else {
        // For other values like number and operator, just add the value to the array
        calculatedMeasures.push({
          value,
          type: valueType,
        });
      }
    }
    return calculatedMeasures;
  }

  // Get measure sqlexpression
  private getCalMeasureSql = (measure: any) => {
    if (!measure) {
      return '';
    }
    const { sqlExpression } = measure.metric || {};
    return sqlExpression || this.getSimpleMeasueSql(measure);
  };

  // For these measures without filter don't have sql expression
  // So, use this function to get the sql expression for simple measure
  // Simple measures example: MAX(column_name)
  private getSimpleMeasueSql(measure: any) {
    const col = this.props.columns.find(
      col => col.column_name === measure.SelectControlColumn,
    );
    const adhocMetric = new AdhocMetric({
      column: col,
      percentile: measure.SelectControlPercentile,
      aggregate: measure.SelectControlAggregator,
      label: measure.MeasureName,
      expressionType: EXPRESSION_TYPES.SIMPLE,
      hasCustomLabel: true,
    });
    return adhocMetric.translateAdvancedMetricsToSql();
  }

  // Check if value is a saved measure or measure in chart
  private isSavedMeasure = (type: any) => type === CalValueTypes.SavedMeasure;

  private isChartMeasure = (type: any) => type === CalValueTypes.ChartMeasure;

  // Get the sql expression for the calculated measure.
  private getCalculatedMeasureSql = (valueArray: any) => {
    let sqlExpression = '';
    for (let j = 0; j < valueArray.length; j += 1) {
      const { value, type } = valueArray[j];
      const valueType = type || getCalValueType(value, this.state.savedMeasures, this.getAllMeasures());

      if (this.isSavedMeasure(valueType)) {
        // If the value is saved measures, then add the value to the sql expression
        const measure = getMeasureByName(value, this.state.savedMeasures);
        sqlExpression += this.getCalMeasureSql(measure);
      } else if (this.isChartMeasure(valueType)) {
        sqlExpression += `"${value}"`;
      } else {
        // If the value is not a measure, then add the value to the sql expression
        sqlExpression += value;
      }
    }
    return sqlExpression;
  };

  // Get the calculation value
  private getCalculationValue = (calculatedMeasure: any) => {
    if (!calculatedMeasure) return '';
    if (calculatedMeasure?.calMeasureVersion === 2) return calculatedMeasure?.calculatedMeasureLogic || '';
    return this.getCalculatedMeasureLogic(calculatedMeasure?.calculatedMeasures || []);
  };

  // This is to handle the change of the calculated measure
  private onCalculatedMeasureChange: (
    i: number,
    type: MultiMetricValueTypes,
    value: any,
  ) => void = (
    i: number,
    type: MultiMetricValueTypes,
    value: any,
  ) => {
    const newValue = [...this.props.value];
    let sqlExpression = ''
    let calculatedMeasureLogic = '';
    if (type !== MultiMetricValueTypes.MeasureName) {
      let measure_ids = []
      let calculatedMeasures: any = [];
      const { savedMeasures } = this.state;
      const allMeasues = this.getAllMeasures();
      calculatedMeasureLogic = value;
      // Get value array
      const valueArray = getCalMeasureArray(value);
      const { isValidExpression, validationErrors } = validateCalculation(value, savedMeasures, allMeasues);
      if (isValidExpression) {
        calculatedMeasures = this.getCalMeasures(valueArray, savedMeasures, allMeasues);
        // Update the sql expression of the calculated measure
        sqlExpression = this.getCalculatedMeasureSql(calculatedMeasures);
        // Update the measure id array
        measure_ids = getMeasureIds(calculatedMeasures, savedMeasures, allMeasues);
      }
      newValue[i] = {
        ...newValue[i],
        isValidExpression,
        validationErrors,
        measure_ids,
        calMeasureVersion: 2,
        calculatedMeasures,
        sqlExpression,
      };
    }
    // If the calculated measure name is changed, 
    // check if measure name is valid and then update the measure name
    else {
      newValue[i] = { ...newValue[i], MeasureName: value, ValidName: !this.checkName(i, value) };
      sqlExpression = newValue[i].metric?.sqlExpression || '';
      calculatedMeasureLogic = newValue[i].calculatedMeasureLogic || '';
    }
    const metric = new AdhocMetric({
      column: { column_name: 'calculated' },
      label: newValue[i].MeasureName,
      expressionType: EXPRESSION_TYPES.SQL,
      hasCustomLabel: true,
      sqlExpression,
    });

    if (this.props.controls.metrics.value) {
      this.props.controls.metrics.value[i] = metric;
    } else {
      this.props.controls.metrics.value = [];
      this.props.controls.metrics.value.push(metric);
    }
    if (this.props.form_data.metrics) {
      this.props.form_data.metrics[i] = metric;
    } else {
      this.props.form_data.metrics = [];
      this.props.form_data.metrics.push(metric);
    }
    newValue[i] = {
      ...newValue[i],
      metric,
      calculatedMeasureLogic,
    };
    this.props.onChange(newValue);
  }

  private removeItem: (i: number) => void = (i: number) => {
    this.onChange(i, MultiMetricValueTypes.MeasureName, "");
    this.props.filterOptions[i] = true;
    if (this.props.controls.metrics.value) {
      this.props.controls.metrics.value =
        this.props.controls.metrics.value.filter(
          (o: any, ix: number) => i !== ix,
        );
    }
    if (this.props.form_data.metrics) {
      this.props.form_data.metrics = this.props.form_data.metrics.filter(
        (o: any, ix: number) => i !== ix,
      );
    }
    this.props.onChange(this.props.value.filter((o, ix) => i !== ix));
  };

  private onSortEnd({ oldIndex, newIndex }: any) {
    this.props.controls.metrics.value = arrayMove(
      this.props.controls.metrics.value,
      oldIndex,
      newIndex,
    );
    this.props.form_data.metrics = arrayMove(
      this.props.form_data.metrics,
      oldIndex,
      newIndex,
    );
    this.props.onChange(arrayMove(this.props.value, oldIndex, newIndex));
  }

  // Get both saved measures and measures in the chart
  private getAllMeasures = () => [
    ...this.getMeasuresInChart(),
    ...this.state.savedMeasures,
  ];

  // Get all measures in the chart
  // saved measures and calculated measures will not be added to the chart
  private getMeasuresInChart = () =>
    this.props.value.filter(function (measure) {
      // Saved measures have measure_id
      // Calculated measure have isCalculatedMeasure = true
      return !(measure.measure_id || measure.isCalculatedMeasure === true);
    });

  handleAceEditorRef(ref: any) {
    if (ref) {
      this.aceEditorRef = ref;
    }
  }

  renderList() {
    const theme = supersetTheme;
    const { datasource, columns } = this.props;
    const sensitiveColumns = datasource?.sensitive_columns || [];
    const nonSensitiveColumns = columns.filter(col => !sensitiveColumns.includes(col?.column_name?.toLowerCase()));

    const keywords =
      this.getAllMeasures()
        .map(measure => {
          if (measure.MeasureName) {
            return {
              name: measure.MeasureName,
              value: measure.MeasureName,
              score: 50,
              meta: 'option',
            };
          }
          return null;
        })
        .filter(Boolean) || [];
    ;
    if (this.props.value.length === 0) {
      return <div className="text-muted">{this.props.placeholder}</div>;
    }
    // if (this.props.value.length === 0){
    // }
    return (
      <SortableList
        useDragHandle
        lockAxis="y"
        bordered
        lockToContainerEdges
        onSortEnd={this.onSortEnd.bind(this)}
        css={{
          borderRadius: theme.gridUnit,
        }}
      >
        <div ref={this.customCollapseRef} />
        {this.props.value.map((o, i) => (
          <SortableListItem
            className="clearfix"
            key={o.key}
            index={i}
            selectable={false}
          >
            {o.isCalculatedMeasure ? (
              <CustomCollapse
                index={i}
                isExpand={o.isExpand}
                extra={
                  <div css={{ display: 'inline-flex', width: '100%' }}>
                    <div
                      css={{
                        flex: 'auto',
                        marginTop: theme.gridUnit,
                      }}
                    >
                      {t('Name')}
                    </div>
                    <div
                      css={{
                        flex: 'auto',
                        width: '100%',
                        marginRight: theme.gridUnit * 2,
                        marginLeft: theme.gridUnit * 2,
                      }}
                    >
                      <TextControl
                        placeholder="Measure Name"
                        value={o.MeasureName}
                        onChange={(value: string) => {
                          this.onCalculatedMeasureChange(
                            i,
                            MultiMetricValueTypes.MeasureName,
                            value,
                          );
                          return {};
                        }}
                      />
                    </div>
                    <div>
                      <InfoTooltipWithTrigger
                        icon="times"
                        label="remove-item"
                        tooltip={t('Remove the measure')}
                        bsStyle="primary"
                        onClick={() => this.removeItem(i)}
                        css={{
                          marginTop: theme.gridUnit * 2,
                        }}
                      />
                      <SortableDragger />
                    </div>
                  </div>
                }
              >
                <div
                  css={{
                    marginTop: theme.gridUnit,
                    marginBottom: theme.gridUnit * 2,
                    width: '100%'
                  }}
                >
                  <SQLEditor
                    ref={this.handleAceEditorRef}
                    keywords={keywords}
                    placeholder='Please enclose measures in "". e.g. "Measure 1" / 100'
                    height="60px"
                    onChange={(value: string) => {
                      this.onCalculatedMeasureChange(
                        i,
                        MultiMetricValueTypes.CalculatedMeasureValue,
                        value,
                      );
                      return {};
                    }}
                    width="100%"
                    showGutter={false}
                    value={this.getCalculationValue(o)}
                    editorProps={{ $blockScrolling: true }}
                    enableLiveAutocompletion
                    wrapEnabled
                    showLoadingForImport
                    style={{
                      border: `1px solid ${theme.colors.grayscale.light2}`,
                      background: theme.colors.grayscale.light5,
                    }}
                  />
                </div>
              </CustomCollapse>
            ) : (
              <CustomCollapse
                index={i}
                isExpand={o.isExpand}
                extra={
                  <div>
                    {o.metric.expressionType === 'SQL' &&
                      new AdhocMetric({
                        ...o.metric,
                        expressionType: 'SIMPLE',
                      }).translateAdvancedMetricsToSql(o.metric.filterSql) !==
                      o.metric.sqlExpression ? (
                      <div
                        css={{
                          width: '100%',
                          marginTop: theme.gridUnit,
                          marginBottom: theme.gridUnit * 3,
                        }}
                      >
                        <Alert
                          type="warning"
                          message={`Cannot load customized SQL: ${o.metric.sqlExpression}`}
                          closable
                        />
                      </div>
                    ) : null}
                    <div css={{ display: 'inline-flex', width: '100%' }}>
                      <div
                        css={{
                          flex: 'auto',
                          marginTop: theme.gridUnit,
                        }}
                      >
                        Name
                      </div>
                      <div
                        css={{
                          flex: 'auto',
                          width: '100%',
                          marginRight: theme.gridUnit * 2,
                          marginLeft: theme.gridUnit * 2,
                        }}
                      >
                        <AutoComplete
                          disabled={
                            this.props.form_data.viz_type !== 'measure' &&
                            this.props.form_data.viz_type !== 'measure_line' &&
                            o.measure_id !== undefined
                          }
                          placeholder="Measure Name"
                          style={{ width: '100%' }}
                          options={this.state.savedMeasures.map(
                            (measure: any) => ({
                              value: measure.MeasureName,
                            }),
                          )}
                          value={o.MeasureName}
                          filterOption={this.props.filterOptions[i]}
                          getPopupContainer={triggerNode =>
                            triggerNode.parentNode
                          }
                          onChange={(value: string) => {
                            this.onChange(
                              i,
                              MultiMetricValueTypes.MeasureName,
                              value,
                            );
                          }}
                          onSelect={(value: string) => {
                            this.onChange(
                              i,
                              MultiMetricValueTypes.SavedMeasure,
                              value,
                            );
                          }}
                        />
                      </div>
                      {this.props.form_data.viz_type !== 'measure' &&
                        this.props.form_data.viz_type !== 'measure_line' ? (
                        <div>
                          <InfoTooltipWithTrigger
                            icon="times"
                            label="remove-item"
                            tooltip={t('Remove the measure')}
                            bsStyle="primary"
                            onClick={() => this.removeItem(i)}
                          />
                          <SortableDragger />
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div
                  css={{
                    display: 'inline-flex',
                    width: '100%',
                    flexWrap: 'wrap',
                  }}
                >
                  {/* This select control is for Percentile */}
                  {o.SelectControlAggregator &&
                    o.SelectControlAggregator === 'PERCENTILE' && (
                      <div
                        css={{
                          flex: 'auto',
                          margin: theme.gridUnit,
                        }}
                      >
                        <SelectControl
                          disabled={
                            this.props.form_data.viz_type !== 'measure' &&
                            this.props.form_data.viz_type !== 'measure_line' &&
                            o.measure_id !== undefined
                          }
                          {...this.props.selectControlPercentileConfig}
                          freeForm
                          onChange={(value: string) =>
                            this.onChange(
                              i,
                              MultiMetricValueTypes.SelectControlPercentile,
                              value,
                            )
                          }
                          value={o.SelectControlPercentile}
                        />
                      </div>
                    )}

                  <div
                    css={{
                      flex: 'auto',
                      margin: theme.gridUnit,
                    }}
                  >
                    <SelectControl
                      disabled={
                        this.props.form_data.viz_type !== 'measure' &&
                        this.props.form_data.viz_type !== 'measure_line' &&
                        o.measure_id !== undefined
                      }
                      notSort
                      {...this.state.selectControlAggregatorConfig}
                      freeForm
                      onChange={(value: string) =>
                        this.onChange(
                          i,
                          MultiMetricValueTypes.SelectControlAggregator,
                          value,
                        )
                      }
                      value={o.SelectControlAggregator}
                    />
                  </div>

                  <div
                    css={{
                      flex: 'auto',
                      margin: theme.gridUnit,
                      paddingTop: theme.gridUnit,
                    }}
                  >
                    {o.SelectControlColumn !== 'events' &&
                      o.SelectControlAggregator === 'COUNT' &&
                      this.props.datasource.database.backend.includes('clickhouse') ? (
                      <span css={{ fontWeight: 'bold' }}>unique values of</span>
                    ) : (
                      'of'
                    )}
                  </div>
                </div>
                <div
                  css={{
                    flex: 'auto',
                    margin: theme.gridUnit,
                  }}
                >
                  <SelectControl
                    disabled={
                      this.props.form_data.viz_type !== 'measure' &&
                      this.props.form_data.viz_type !== 'measure_line' &&
                      o.measure_id !== undefined
                    }
                    // isFromMultiMetric
                    {...this.props.selectControlColumnConfig}
                    onChange={(value: string) =>
                      this.onChange(
                        i,
                        MultiMetricValueTypes.SelectControlColumn,
                        value,
                      )
                    }
                    options={
                      o.SelectControlAggregator === 'COUNT'
                        ? [
                          ...this.props.columns.map(column => ({
                            value: column.column_name,
                            label: column.verbose_name || column.column_name,
                            key: column.id,
                            customLabel: this.renderColumnOption(column),
                          })),
                          { value: 'events', label: 'events', key: 'events' },
                        ]
                        : o.SelectControlAggregator === 'PERCENTILE'
                          ? this.props.columns
                            .filter(
                              column =>
                                column.type !== 'INT8' &&
                                column.type !== 'ARRAY(NULLABLE(INT32))' &&
                                column.type !== 'BOOL' &&
                                column.type !== 'DATETIME' &&
                                column.type !== 'STRING',
                            )
                            .map(column => ({
                              value: column.column_name,
                              label: column.verbose_name || column.column_name,
                              key: column.id,
                              customLabel: this.renderColumnOption(column),
                            }))
                          : this.props.columns.map(column => ({
                            value: column.column_name,
                            label: column.verbose_name || column.column_name,
                            key: column.id,
                            customLabel: this.renderColumnOption(column),
                          }))
                    }
                    value={o.SelectControlColumn}
                  />
                </div>
                <div>
                  <div title="filter">
                    {this.props.datasource.database.backend.includes('clickhouse') ? (
                      <div
                        css={{
                          flex: 'auto',
                          display: 'inline-flex',
                          width: '100%',
                        }}
                      >
                        <div
                          css={{
                            flex: '1',
                            margin: theme.gridUnit,
                            paddingTop: theme.gridUnit * 5,
                          }}
                        >
                          Filtered to
                        </div>

                        <div
                          css={{
                            flex: '4',
                            margin: theme.gridUnit,
                          }}
                        >
                          <AdvancedMetricFilterControl
                            disabled={
                              this.props.form_data.viz_type !== 'measure' &&
                              this.props.form_data.viz_type !==
                              'measure_line' &&
                              o.measure_id !== undefined
                            }
                              columns={nonSensitiveColumns}
                            datasource={this.props.datasource}
                              sections={[]}
                            selectedMetrics={this.props.selectedMetrics}
                            {...this.props.adhocFilterControlConfig}
                            onChange={(value: string | any[]) =>
                              this.onChange(
                                i,
                                MultiMetricValueTypes.AdhocFilterControl,
                                value,
                              )
                            }
                            value={
                              this.props.value[i][
                              MultiMetricValueTypes.AdhocFilterControl
                              ]
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {this.props.form_data.viz_type === 'measure' ||
                  this.props.form_data.viz_type === 'measure_line' ? (
                  <div
                    css={{
                      display: 'inline-flex',
                      width: '100%',
                      marginTop: theme.gridUnit,
                    }}
                  >
                    <div
                      css={{
                        flex: 'auto',
                        marginTop: theme.gridUnit,
                      }}
                    >
                      Tag
                    </div>
                    <div
                      css={{
                        flex: 'auto',
                        width: '100%',
                        marginLeft: theme.gridUnit * 2,
                      }}
                    >
                      <AsyncSelect
                        ariaLabel="Tags"
                        mode="multiple"
                        value={o.tags ? o.tags.map(t => ({ label: t, value: t, key: t })) : []}
                        options={loadTags}
                        onChange={(values: { label: string; value: number }[]) => {
                          const uniqueTags = [...new Set(values.map(v => v.label))];
                          this.onChange(i, MultiMetricValueTypes.tags, [...uniqueTags]);
                        }}
                        onClear={() => { this.onChange(i, MultiMetricValueTypes.tags, []); }}
                        allowClear
                        allowNewOptions
                      />
                    </div>
                  </div>
                ) : null}
              </CustomCollapse>
            )}
          </SortableListItem>
        ))}
      </SortableList>
    );
  }

  render() {
    const theme = supersetTheme;
    return (
      <div
        data-test="MultiMetricsControl"
        className="MultiMetricsControl"
        css={{
          'span :focus': {
            outline: '0px !important',
          },
        }}
      >
        <HeaderContainer>
          <ControlHeader {...this.props} />
        </HeaderContainer>
        {this.renderList()}
        {this.props.form_data.viz_type !== 'measure' &&
          this.props.form_data.viz_type !== 'measure_line' ? (
          <>
            <AddIconButton
              onClick={this.onAdd}
              style={{ float: 'left', marginRight: '0.4em', marginTop: '1em' }}
              disabled={!this.props.multi && this.props.value.length >= 1}
            >
              <Icons.PlusLarge
                iconSize="s"
                iconColor={theme.colors.grayscale.light5}
              />
            </AddIconButton>
            <div style={{ marginTop: '0.9em' }}>Measure</div>

            <AddIconButton
              onClick={this.onAddCalculation}
              style={{ float: 'left', marginRight: '0.4em', marginTop: '1em' }}
              disabled={!this.props.multi && this.props.value.length >= 1}
            >
              <Icons.PlusLarge
                iconSize="s"
                iconColor={theme.colors.grayscale.light5}
              />
            </AddIconButton>
              <div style={{ marginTop: '0.9em' }}>Calculated Measure <SectionValidateTooltip /></div>
          </>
        ) : null}
      </div>
    );
  }
}

export default withTheme(MultiMetricControl);
