import { ensureIsArray, t } from '@superset-ui/core';
import { Operators } from 'src/explore/constants';
import { theme } from 'src/preamble';
import React from 'react';
import Button from 'src/components/Button';
import ControlHeader from '../../ControlHeader';
import { IAdvancedMetricFilter } from '../AdvancedAdhocMetricFilter/AdvancedMetricFilter.type';
import AdvancedMetricFilterGroup, {
  generateFilterKey,
} from '../AdvancedAdhocMetricFilter/AdvancedMetricFilterGroup';
import AdhocFilter from '../FilterControl/AdhocFilter';
import AdhocFilterPopoverTrigger from '../FilterControl/AdhocFilterPopoverTrigger';
import AdhocMetric from '../MetricControl/AdhocMetric';
import { AddIconButton } from '../OptionControls';

export interface IAdvancedFilterPopoverTriggerProps {
  disabled?: boolean;
  columns: any[];
  datasource: object;
  operators?: Operators[];
  sections: string[];
  selectedMetrics: object;
  name?: string;
  label?: string;
  description?: string;
  filterGroup: AdvancedMetricFilterGroup;
  selectedFilter?: IAdvancedMetricFilter;
  selectedFilterGroup: AdvancedMetricFilterGroup;
  triggerChange: () => void;
  onSelectFilter: (filter: IAdvancedMetricFilter) => void;
  onSelectFilterGroup: (filterGroup: AdvancedMetricFilterGroup) => void;
  value?: any;
}

export default function AdvancedFilterPopoverTrigger(
  props: IAdvancedFilterPopoverTriggerProps,
): JSX.Element {
  function addFilter(newFilter: AdhocFilter): void {
    let newAdvancedFilter: IAdvancedMetricFilter;
    if (props.selectedFilter) {
      let hasFilterGroup = props.selectedFilter.container;
      while (
        hasFilterGroup &&
        hasFilterGroup.filters.length === 0 &&
        hasFilterGroup.parent
      ) {
        hasFilterGroup = hasFilterGroup.parent;
      }
      newAdvancedFilter = {
        key: generateFilterKey(),
        adhocFilter: newFilter,
        container: hasFilterGroup,
      };

      hasFilterGroup.addFilter(newAdvancedFilter);
    } else {
      newAdvancedFilter = {
        key: generateFilterKey(),
        adhocFilter: newFilter,
        container: props.selectedFilterGroup,
      };
      props.selectedFilterGroup.addFilter(newAdvancedFilter);
    }
    props.onSelectFilter(newAdvancedFilter);
    props.onSelectFilterGroup(newAdvancedFilter.container);
    props.triggerChange();
  }

  function addFilterGroup(newFilter: AdhocFilter): void {
    const newAdvancedFilterGroup = new AdvancedMetricFilterGroup(
      props.selectedFilterGroup,
    );
    const newAdvancedFilter = {
      key: generateFilterKey(),
      adhocFilter: newFilter,
      container: newAdvancedFilterGroup,
    };
    newAdvancedFilterGroup.addFilter(newAdvancedFilter);
    props.selectedFilterGroup.addFilterGroups(newAdvancedFilterGroup);
    props.onSelectFilter(newAdvancedFilter);
    props.onSelectFilterGroup(newAdvancedFilterGroup);
    props.triggerChange();
  }

  function optionsForSelect(): any[] {
    const options = [
      ...props.columns,
      ...ensureIsArray(props.selectedMetrics).map(
        metric =>
          metric &&
          (typeof metric === 'string'
            ? { saved_metric_name: metric }
            : new AdhocMetric(metric)),
      ),
    ].filter(option => option);

    return options
      .reduce((results, option) => {
        if (option.saved_metric_name) {
          results.push({
            ...option,
            filterOptionName: option.saved_metric_name,
          });
        } else if (option.column_name) {
          results.push({
            ...option,
            filterOptionName: `_col_${option.column_name}`,
          });
        } else if (option instanceof AdhocMetric) {
          results.push({
            ...option,
            filterOptionName: `_adhocmetric_${option.label}`,
          });
        }
        return results;
      }, [])
      .sort((a: any, b: any) =>
        (a.saved_metric_name || a.column_name || a.label).localeCompare(
          b.saved_metric_name || b.column_name || b.label,
        ),
      );
  }

  return (
    <div data-test="adhoc-filter-control" style={{ width: '100%' }}>
      <ControlHeader {...props} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <AdhocFilterPopoverTrigger
          operators={props.operators}
          sections={props.sections}
          adhocFilter={new AdhocFilter({})}
          datasource={props.datasource}
          options={optionsForSelect()}
          onFilterEdit={(newFilter: AdhocFilter) => addFilter(newFilter)}
        >
          <AddIconButton
            disabled={props.disabled}
            data-test="add-filter-button"
            style={{
              display: 'flex',
              width: '30%',
              backgroundColor: theme.colors.grayscale.light3,
              minWidth: '90px',
            }}
          >
            {/* <Icons.PlusLarge iconSize="s" /> */}
            <Button
              buttonSize="xsmall"
              disabled={props.disabled}
              style={{ padding: theme.gridUnit, width: '100%' }}
            >
              {t('+ Add Filters')}
            </Button>
          </AddIconButton>
        </AdhocFilterPopoverTrigger>
        <AdhocFilterPopoverTrigger
          operators={props.operators}
          sections={props.sections}
          adhocFilter={new AdhocFilter({})}
          datasource={props.datasource}
          options={optionsForSelect()}
          onFilterEdit={(newFilter: AdhocFilter) =>
            props.value?.length > 0
              ? addFilterGroup(newFilter)
              : addFilter(newFilter)
          }
        >
          <AddIconButton
            disabled={props.disabled}
            data-test="add-filter-button"
            style={{
              display: 'flex',
              width: '30%',
              backgroundColor: theme.colors.grayscale.light3,
              minWidth: '90px',
            }}
          >
            {/* <Icons.PlusLarge iconSize="s" /> */}
            <Button
              disabled={props.disabled}
              buttonSize="xsmall"
              style={{ padding: theme.gridUnit, width: '100%' }}
            >
              {t('+ Add Groups')}
            </Button>
          </AddIconButton>
        </AdhocFilterPopoverTrigger>
      </div>
    </div>
  );
}
